import axios from "axios"

const prefix = "flujo-dinero/cajas";

const flujoDinero = {
    listaTransferenciasCajas: params => axios.get(`${prefix}/lista`, { params }),
    selectCajas: () => axios.get(`${prefix}/select`),
    tablaCajas: (params) => axios.get(`${prefix}/tabla`, { params }),
    crearCaja: params => axios.post(`${prefix}/caja`, params ),
    editarCaja: params => axios.put(`${prefix}/caja/${params.id}`, params ),
    eliminarCaja: id => axios.delete(`${prefix}/caja/${id}`),
    cambioEstadoCaja: id => axios.put(`${prefix}/cambio-estado/${id}`),
    crearIngresoTesoreria: params => axios.post(`${prefix}/ingreso-tesoreria`, params ),
    crearRetiroAdministrador: params => axios.post(`${prefix}/ingreso-retiro`, params ),
    crearVericacionCaja: params => axios.post(`${prefix}/verificar-caja`, params ),
    anularTesoreria: params => axios.post(`${prefix}/anular-tesoreria`, params ),

    historialTesorerias: params => axios.get(`${prefix}/historial-tesorerias`, { params }),
    historialVerificaciones: params => axios.get(`${prefix}/historial-verificaciones`, { params }),
    historialPagos: params => axios.get(`${prefix}/historial-pagos`, { params }),


    getCuentasYCajasXCedis: idCedis => axios.get(`${prefix}/cedis/${idCedis}/get-cajas`),
};

export default flujoDinero
