<template>
    <section class="">
        <div class="row mx-0 align-items-center">
            <div class="btn-back" @click="$router.back();">
                <i class="icon-left f-12" />
            </div>
            <div class="col-auto text-general f-17">
                <b>Movimientos de:</b>
                {{ caja.nombre }}
            </div>
            <div class="col-auto text-general f-600">
                {{ dia }}
            </div>
        </div>
        <div class="d-flex mx-0 mt-3">
            <div class="col mr-2 bg-white br-t-5">
                <div class="row mx-0 align-items-center border-bottom text-general br-t-5" style="height:42px;">
                    <div class="col-auto">
                        Pagos por deudas:
                        <b>{{ separadorNumero(sumaPagos) }}</b>
                    </div>
                </div>
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 175px)">
                    <div v-for="(data, p) in pagos" :key="p" class="mx-1 px-1 py-2 my-2 mb-3 border br-8" @click="detallePago(data)">
                        <div class="row mx-0 align-items-center f-16 text-general">
                            <img :src="data.encargado.foto" width="36" height="36" class="rounded-circle border" />
                            <div class="col tres-puntos">
                                {{ data.encargado.nombre }}
                            </div>
                            <div class="col text-right tres-puntos">
                                <p>{{ formatearFecha(data.created_at, 'D MMM Y - HH:mmA') }}</p>
                            </div>
                        </div>
                        <div class="row mx-0 align-items-center mt-1 f-16 text-general">
                            <i class="icon-pedidos ml-2 text-general f-18" />
                            <div class="col tres-puntos">
                                {{ data.id_pedido_admin }}
                            </div>
                            <div class="col text-right tres-puntos f-600">
                                {{ separadorNumero(data.valor) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col mx-2  bg-white br-t-5">
                <div class="row mx-0 align-items-center border-bottom text-general br-t-5" style="height:42px;">
                    <div class="col-auto">
                        Ingresos:
                        <b>{{ separadorNumero(sumaIngresos) }}</b>
                    </div>
                    <div v-if="(fecha == hoy || fecha == ayer) && restante > 0 && $can('boton_flujo_dinero_cajas_ingreso_tesoreria')" class="col-auto d-middle ml-auto">
                        <el-tooltip placement="bottom" :content="`Faltan ${separadorNumero(restante)} por ingresar`" effect="light">
                            <i class="icon-alert-triangle text-warning f-18 mr-2" />
                        </el-tooltip>
                        <el-tooltip placement="bottom" content="Ingresar a la caja fuerte" effect="light">
                            <div class="br-4 text-white  cr-pointer d-middle-center bg-general" style="height:18px;width:18px;" @click="ingresarDinero">
                                <i class="icon-plus" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 175px)">
                    <div v-for="(ing, i) in hisotrialTesoreria" :key="i" class="mx-1 px-1 py-2 my-2 mb-3 border cr-pointer br-8" @click.stop="registro(ing)">
                        <div class="row mx-0 align-items-center f-16 text-general">
                            <img :src="ing.responsable.foto" width="36" height="36" class="rounded-circle obj-cover border" />
                            <div class="col tres-puntos">
                                {{ ing.responsable.nombre }}
                            </div>
                            <div class="col text-right tres-puntos">
                                {{ formatearFecha(ing.created_at, 'D MMM Y - HH:mmA') }}
                            </div>
                        </div>
                        <div class="row mx-0 align-items-center mt-1 f-16 text-general">
                            <div class="col-auto px-3" />
                            <div class="col tres-puntos f-600">
                                {{ separadorNumero(ing.valor) }}
                            </div>
                            <el-popover
                            v-if="ing.anulado_justificacion == null && (fecha == hoy || fecha == ayer) && $can('boton_flujo_dinero_cajas_anular_tesoreria')"
                            placement="bottom"
                            trigger="hover"
                            >
                                <div class="row mx-0 text-general cr-pointer py-1 px-2" @click.stop="anularRegistro(ing)">
                                    Anular registro
                                </div>
                                <div slot="reference" class="col text-right tres-puntos f-600">
                                    <i class="icon-dots-vertical" />
                                </div>
                            </el-popover>
                            <div v-else-if="ing.anulado_justificacion != null" class="bg-pink text-white br-20 f-14 px-2">
                                Anulado
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col mx-2 bg-white br-t-5">
                <div class="row mx-0 align-items-center border-bottom text-general br-t-5" style="height:42px;">
                    <div class="col-auto">
                        Movimientos manuales
                        <b>{{ separadorNumero(sumaMovimientos) }}</b>
                    </div>
                    <el-tooltip v-if="hoy == fecha && $can('boton_flujo_dinero_cajas_retiro_admin')" placement="bottom" content="Retiro / ingreso manual" effect="light">
                        <div class="br-4 text-white cr-pointer ml-auto d-middle-center bg-general3" style="height:18px;width:18px;" @click="retiroManual">
                            <i class="icon-plus" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 175px)">
                    <div v-for="(mv, m) in historialMovimiento" :key="m" class="mx-1 px-1 py-2 my-2 mb-3 border br-8">
                        <div class="row mx-0 align-items-center f-16 text-general">
                            <img :src="mv.responsable.foto" width="36" height="36" class="rounded-circle obj-cover border" />
                            <div class="col tres-puntos">
                                {{ mv.responsable.nombre }}
                            </div>
                            <div class="col text-right tres-puntos">
                                {{ formatearFecha(mv.created_at, 'D MMM Y - HH:mmA') }}
                            </div>
                        </div>
                        <div class="row mx-0 align-items-center mt-1 f-16 text-general">
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content" style="width:280px">
                                    {{ mv.observaciones }}
                                </div>
                                <i class="icon-message-text-outline ml-2 text-general f-18" />
                            </el-tooltip>
                            <div class="col tres-puntos" :class="mv.tipo == 0 ? 'text-general-red' : 'text-green'">
                                {{ separadorNumero(mv.valor) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalAnularRegistro" titulo="Anular Registro" no-aceptar adicional="Anular" @adicional="anularTesoreria">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center py-3">
                    <div class="col-10">
                        <p class="text-general pl-3 f-14">
                            Justifica la anulación de este registro
                        </p>
                        <ValidationProvider v-slot="{errors}" vid="justificacion2" rules="required|max:150" name="justificación">
                            <el-input v-model="justificacion" placeholder="Justificación" class="w-100" type="textarea" :rows="5" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modal-ingresar-dinero ref="modalIngresarDinero" @ingreso="ingresoCaja" />
        <modal-retiro-manual ref="modalRetiroManual" @ingreso="ingresoManual" />
        <modal-detalle-pago ref="modalDetallePago" />
        <modal-registro ref="modalRegistro" @anular="anularRegistro" />
    </section>
</template>

<script>
import Cajas from "~/services/flujoDinero/cajas";
import { mapGetters } from 'vuex'
import moment from 'moment';
export default {
    components: {
        modalIngresarDinero: () => import('./partials/modalIngresarDinero'),
        modalRetiroManual: () => import('./partials/modalRetiroManual'),
        modalDetallePago: () => import('./partials/modalDetallePago'),
        modalRegistro: () => import('./partials/modalRegistro')
    },
    data(){
        return{
            model: {},
            justificacion: '',
            hisotrialTesoreria: [],
            historialMovimiento: [],
            pagos: [],
            hoy: moment().format('Y-MM-DD'),
            ayer: moment().subtract(1,'days').format('Y-MM-DD'),
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            caja:'flujoDinero/caja/caja',
            dia:'flujoDinero/caja/dia',
            fecha:'flujoDinero/caja/fecha',
        }),
        sumaPagos(){
            let suma = 0
            suma = _.sumBy(this.pagos,'valor')
            return suma
        },
        sumaIngresos(){
            let suma = 0
            suma = _.sumBy(this.hisotrialTesoreria.filter(el =>  el.estado == 1),'valor')
            return suma
        },
        sumaMovimientos(){
            let suma = 0
            suma = _.sumBy(this.historialMovimiento.filter(el =>  el.tipo == 0),'valor')
            return suma
        },
        restante(){
            return this.sumaPagos - this.sumaIngresos
        }
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.init()
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        init(){
            if(this.fecha == null){
                this.$router.push({name: 'admin.tesoreria.cajas'})
                return
            }
            this.historialPagos()
            this.historialTesorerias()
            this.historialMovimientos()
        },
        anularRegistro(row){
            this.model = row
            this.$refs.modalAnularRegistro.toggle();
        },
        ingresarDinero(){
            const params = {
                fecha: this.fecha,
                valor_pendiente: this.restante,
                id_caja: this.$route.params.id_caja,
                sigla: this.caja.sigla,
                moneda: this.caja.idm_moneda,
            }
            this.$refs.modalIngresarDinero.toggle(params)
        },
        retiroManual(){
            const params = {
                id_caja: this.$route.params.id_caja,
                sigla: this.caja.sigla,
                moneda: this.caja.idm_moneda,
            }
            this.$refs.modalRetiroManual.toggle(params);
        },
        async anularTesoreria(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.model.anulado_justificacion = this.justificacion
                this.model.idm_moneda = this.id_moneda

                const { data } = await Cajas.anularTesoreria(this.model)
                this.notificacion('Mensaje', data.message, 'success')
                this.$refs.modalAnularRegistro.toggle()
                let index = this.hisotrialTesoreria.findIndex(el => el.id == data.pago.id)
                this.$set(this.hisotrialTesoreria, index, data.pago)
                this.model = {}
            } catch (error){
                this.error_catch(error)
            }
        },
        retirar(){
            this.$refs.modalRetiroManual.toggle();
        },
        detallePago(row){
            this.$refs.modalDetallePago.toggle(row, 1);
        },
        registro(row){
            this.$refs.modalRegistro.toggle(row);
        },
        async historialPagos(){
            try {
                const params = {
                    id_moneda: this.id_moneda,
                    id_caja: this.$route.params.id_caja,
                    fecha: this.fecha
                }
                const { data } = await Cajas.historialPagos(params)
                this.pagos = data.data.pagos

            } catch (error){
                this.error_catch(error)
            }
        },
        async historialTesorerias(){
            try {
                const params = {
                    id_moneda: this.id_moneda,
                    id_caja: this.$route.params.id_caja,
                    fecha: this.fecha
                }
                const { data } = await Cajas.historialTesorerias(params)
                this.hisotrialTesoreria = data.data.hisotrialTesoreria

            } catch (error){
                this.error_catch(error)
            }
        },
        async historialMovimientos(){
            try {
                const params = {
                    id_moneda: this.id_moneda,
                    id_caja: this.$route.params.id_caja,
                    fecha: this.fecha
                }
                const { data } = await Cajas.historialMovimientos(params)
                this.historialMovimiento = data.data.historialMovimiento

            } catch (error){
                this.error_catch(error)
            }
        },
        ingresoCaja({ pago, saldo}){
            this.hisotrialTesoreria.unshift(pago)
        },
        ingresoManual({ pago, saldo}){
            this.historialMovimiento.unshift(pago)
        },
    }
}
</script>
<style lang="scss" scoped>
.text-green{
    color: #28D07B;
}
.bg-pink{
    background-color: #FF5A60;
}
</style>
